import React, { Component } from "react";
import styles from "./section.module.css";
import descriptionImage from "../../../images/world-green-grass.jpg";
import descriptionIcon from "../../../images/descriptionIcon.jpg";
import applicationsImage from "../../../images/who-we-are.jpg";
import applicationsIcon from "../../../images/applications.jpg";
import charcImage from "../../../images/charcs-chimestry.jpg";
import charcIcon from "../../../images/charcIcon.jpg";
import benefitsImage from "../../../images/benefitsImage.jpg";
import benefitsIcon from "../../../images/benefitsIcon.jpg";
import { Row, Col } from "react-bootstrap";

class Section extends Component {
	state = {
		tableNumberLimit: 5,
	};

	render() {
		let section;
		const { type } = this.props;
		const { product } = this.props;
		let img;
		let description;
		let applications;
		let characteristics;
		let benefits;
		let icon;

		switch (type) {
			case "description":
				description = product.description;
				icon = <img src={descriptionIcon} alt="desc-icon" />;
				img = (
					<img src={descriptionImage} alt="desc-img" className={styles.image} />
				);
				section = (
					<Row>
						<Col xs={12} xl={6}>
							<div className={styles.textContainer}>
								<div className={styles.textHeadlineContainer}>
									<div className={styles.textHeadline}>
										<div className={styles.circle}>{icon}</div>
										<h1>{type}</h1>
									</div>
								</div>
								<p className={styles.text}>{description}</p>
							</div>
						</Col>
						<Col xs={12} xl={6} className="d-flex justify-content-center">
							{img}
						</Col>
					</Row>
				);
				break;
			case "applications":
				applications = product.applications;
				icon = <img src={applicationsIcon} alt="applications-icon" />;
				img = (
					<img
						src={applicationsImage}
						alt="applications-img"
						className={styles.image}
					/>
				);
				section = (
					<Row className="flex-md-row-reverse">
						<Col xs={12} xl={6}>
							<div className={styles.textContainer}>
								<div className={styles.textHeadlineContainer}>
									<div className={styles.textHeadline}>
										<div
											style={{ background: "white" }}
											className={styles.circle}
										>
											{icon}
										</div>
										<h1>{type}</h1>
									</div>
								</div>
								<ul className={styles.text}>
									{[...Object.values(applications)].map(
										(application, index) => {
											if (applications.description) {
												return (
													<li
														className={styles.applicationListItem}
														key={index}
													>
														{application}
													</li>
												);
											} else {
												return <li key={index}>{application}</li>;
											}
										}
									)}
								</ul>
							</div>
						</Col>
						<Col xs={12} xl={6} className="d-flex justify-content-center">
							{img}
						</Col>
					</Row>
				);
				break;
			case "benefits":
				benefits = product.benefits;
				icon = <img src={benefitsIcon} alt="benefits-icon" />;
				img = (
					<img
						src={benefitsImage}
						alt="benefits-img"
						className={styles.image}
					/>
				);
				section = (
					<Row className="flex-md-row-reverse">
						<Col xs={12} xl={6}>
							<div className={styles.textContainer}>
								<div className={styles.textHeadlineContainer}>
									<div className={styles.textHeadline}>
										<div
											style={{ background: "white" }}
											className={styles.circle}
										>
											{icon}
										</div>
										<h1>{type}</h1>
									</div>
								</div>
								<ul className={styles.text}>
									{[...Object.values(benefits)].map((benefit, index) => {
										return <li key={index}>{benefit}</li>;
									})}
								</ul>
							</div>
						</Col>
						<Col xs={12} xl={6} className="d-flex justify-content-center">
							{img}
						</Col>
					</Row>
				);
				break;
			case "characteristics":
				characteristics = product.characteristics;
				icon = <img src={charcIcon} alt="charc-icon" />;
				img = <img src={charcImage} alt="charc-img" className={styles.image} />;
				const keys = [...Object.keys(characteristics)];
				const values = [...Object.values(characteristics)];

				section = (
					<Row>
						<Col xs={12} xl={6}>
							<div className={styles.textContainer}>
								<div className={styles.textHeadlineContainer}>
									<div className={styles.textHeadline}>
										<div className={styles.circle}>{icon}</div>
										<h1 className={styles.headingCharac}>{type}</h1>
									</div>
								</div>
								<div className="table-responsive-sm">
									<table className="table">
										<tbody>
											<tr>
												{keys
													.slice(0, this.state.tableNumberLimit)
													.map((key, index) => {
														return <th key={index}>{key}</th>;
													})}
											</tr>

											<tr>
												{values
													.slice(0, this.state.tableNumberLimit)
													.map((value, index) => {
														return <td key={index}>{value}</td>;
													})}
											</tr>

											<tr>
												{keys
													.slice(this.state.tableNumberLimit)
													.map((key, index) => {
														return <th key={index}>{key}</th>;
													})}
											</tr>
											<tr>
												{values
													.slice(this.state.tableNumberLimit)
													.map((value, index) => {
														return <td key={index}>{value}</td>;
													})}
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</Col>
						<Col xs={12} xl={6} className="d-flex justify-content-center">
							{img}
						</Col>
					</Row>
				);
				break;

			default:
		}

		return <>{section}</>;
	}
}

export default Section;
