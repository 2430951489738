import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer text-center text-white">
        <div className="container p-1">
          {/* <section class="">
      
      <div class="row">
        
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text">Kohav Aviation Chemtech</h5>
        </div>
        
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text">Yigal Alon 40 St, Herzliya, Israel</h5>
        </div>

        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text"> <a href="mailto:info@kacgalaxy.com">info@kacgalaxy.com</a></h5>
        </div>

        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text">+972 53-940-6985</h5>
        </div>

      </div>  
    </section> */}
        </div>
        <div className="text-center p-3" style={{ color: "white" }}>
          © 2021 Copyright:
          <a
            className="text-white"
            href="https://www.facebook.com/GlobalXTech"
            target="_blank"
          >
            {" "}
            K.A.C
          </a>
        </div>
      </footer>
      {/* <div className="footer">
        <svg
          viewBox="0 -20 700 110"
          width="100%"
          height="210"
          preserveAspectRatio="none"
        >
          <path
            transform="translate(0, -20)"
            d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700"
            fill="#eff1e4"
          />
          <path
            d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z"
            fill="#00273F"
          />
        </svg>
        <div className="footerContainer">
          <div className="footerContainerCopyright">
            <h4 className="footerContainerHeadline">Green Galaxy</h4>
            <h6 className="footerContainerSubHeadline">K.A.C</h6>
            <h6 className="footerContainerSubHeadline">
              Kohav Aviation Chemtech
            </h6>
            <h6 className="footerContainerSubHeadlineCopyright">
              Copyright @ 2020 K.A.C. All rights reserved
            </h6>
          </div>
          <ul className="footerContainerItems">
            <li className="footerContainerItem">
              <span className="footerContainerItemContent">
                Yigal Alon 40 St, Herzliya, Israel
              </span>
            </li>
            <li className="footerContainerItem">
              
              <span className="footerContainerItemContent">
                <a href="mailto:info@kacgalaxy.com">info@kacgalaxy.com</a>
              </span>
            </li>
            <li className="footerContainerItem">
           
              <span className="footerContainerItemContent">
                +972 53-940-6985
              </span>
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
};

export default Footer;
