import React from "react";
import styles from "./approvedSection.module.css";
import approvedIcon from "../../../../images/approvedIcon.jpg";
import { Container, Row, Col } from "react-bootstrap";

const ApprovedSection = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={styles.textHeadlineContainer}>
            <div className={`${styles.textHeadline} ${styles.textPacking}`}>
              <div style={{ background: "white" }} className={styles.circle}>
                <img src={approvedIcon} alt="approved-icon" />
              </div>
              <h1>approved</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xs={12}
          md={6}
          xl={4}
          className="d-flex align-items-center justify-content-center"
        >
          <div className={`${styles.badge} ${styles.red}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              width="216px"
              height="232px"
              viewBox="0 0 216 232"
            >
              <path
                fill="#2B2B2B"
                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z"
              />
            </svg>
            <p className={styles.title}>Sandwich corrosion test</p>
            <p className={styles.subtitle}>ASTM F1110</p>
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          xl={4}
          className="d-flex align-items-center justify-content-center"
        >
          <div className={`${styles.badge} ${styles.green}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              width="216px"
              height="232px"
              viewBox="0 0 216 232"
            >
              <path
                fill="#2B2B2B"
                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z"
              />
            </svg>
            <p className={styles.title}>Acrylic Crazing Test</p>
            <p className={styles.subtitle}>ASTM F484</p>
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          xl={4}
          className="d-flex align-items-center justify-content-center"
        >
          <div className={`${styles.badge} ${styles.gray}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              width="216px"
              height="232px"
              viewBox="0 0 216 232"
            >
              <path
                fill="#2B2B2B"
                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z"
              />
            </svg>
            <p className={styles.title}>Paint softening test</p>
            <p className={styles.subtitle}>ASTM F 502</p>
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          xl={4}
          className="d-flex align-items-center justify-content-center"
        >
          <div className={`${styles.badge} ${styles.blue}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              width="216px"
              height="232px"
              viewBox="0 0 216 232"
            >
              <path
                fill="#2B2B2B"
                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z"
              />
            </svg>
            <p className={styles.title}>Hydrogen embrittlement</p>
            <p className={styles.subtitle}>ASTM F519-93</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ApprovedSection;
