import React, { Component } from "react";
import styles from "./productsHeader.module.css";
import aircraft from "../../images/aircraft.png";
import rail from "../../images/rail.png";
import industry from "../../images/industry.png";
import special from "../../images/special.png";
import cosmetic from "../../images/cosmetic-line.webp";
import stamp1 from "../../images/stamp1.png";
import stamp2 from "../../images/stamp2.png";
import greenstamp1 from "../../images/greenstamp1.png";
import greenstamp2 from "../../images/greenstamp2.png";
import bioStamps from "../../images/bioStamps.png";
import containers from "../../images/containers.png";
import { withRouter } from "react-router-dom";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import Soap from "../../images/galaxy-clean-19-nobackground.png";
import Cream from "../../images/galaxy-cream-header-nobackground.png";
import ScrollTrigger from "react-scroll-trigger";
import { Container, Row, Col } from "react-bootstrap";
import coverImageWeapons from "../../images/CoverImage.png";

class ProductsHeader extends Component {
	state = { visible: true };

	onExitViewport = () => {
		this.setState({
			visible: false,
		});
	};

	render() {
		let img = null;

		switch (this.props.pageTitle.toLowerCase()) {
			case "aircraft-cleaners":
				img = (
					<img className={styles.headerImage} src={aircraft} alt="aircraft" />
				);
				break;
			case "rail-cleaners":
				img = <img className={styles.headerImage} src={rail} alt="rail" />;
				break;
			case "industry-cleaners":
				img = (
					<img
						className={`${styles.headerImage} ${styles.industry}`}
						src={industry}
						alt="industry"
					/>
				);
				break;
			case "special-cleaners":
				img = (
					<img
						className={`${styles.headerImage} ${styles.special} ${styles.industry} `}
						src={special}
						alt="special"
					/>
				);
				break;
			case "galaxy-antibacterial-hand-cream":
				img = (
					<img
						className={` ${styles.cosmeticImageHeader}`}
						src={Cream}
						alt="cream"
					/>
				);
				break;
			case "galaxy-clean-19":
				img = (
					<img
						className={` ${styles.cosmeticImageHeader}`}
						src={Soap}
						alt="soap"
					/>
				);
				break;
			case "weapon-cleaners":
				img = (
					<img
						className={`${styles.headerImage} ${styles.width} `}
						src={coverImageWeapons}
						alt="cover"
					/>
				);
				break;
			default:
				img = (
					<img
						className={`${styles.headerImage} ${styles.width} `}
						src={containers}
						alt="containers"
					/>
				);
		}
		return (
			<>
				<section className={styles.details}>
					<div className={styles.container}>
						<div className="content-wrapper">
							<Container fluid>
								<Row className="py-3">
									<Col
										xs={12}
										md={12}
										className={`${styles.headerHero} py-5 my-5`}
									>
										<h1 className="text-dark">
											{this.props.pageTitle.toUpperCase().replace(/-/g, " ")}
										</h1>
										<hr />
										{this.props.pageTitle === "special-cleaners" ||
										this.props.showButton ? (
											this.state.visible ? (
												<ScrollTrigger onExit={this.onExitViewport}>
													<Tooltip
														defaultVisible={true}
														placement="left"
														overlay={
															<div className={styles.tooltip}>
																Click to see live demonstration!
															</div>
														}
													>
														<button
															className={styles.button}
															onClick={this.props.changeVideoMode}
														>
															Watch Video
														</button>
													</Tooltip>
												</ScrollTrigger>
											) : (
												<button
													className={styles.button}
													onClick={this.props.changeVideoMode}
												>
													Watch Video
												</button>
											)
										) : null}
										{this.props.pageTitle === "aircraft-cleaners" ||
										this.props.match.params.section === "aircraft-cleaners" ? (
											<div className={styles.stampsContainer}>
												{" "}
												<img
													className={styles.stampImage}
													src={stamp1}
													alt="stamp1"
												/>
												<img
													className={styles.stampImage}
													src={stamp2}
													alt="stamp2"
												/>
											</div>
										) : null}
										{this.props.match.params.section !== "cosmetic-line" ? (
											<div className={`${styles.bioStamps} d-none d-md-flex`}>
												<img
													className={styles.stampImage}
													src={greenstamp1}
													alt="bio-stamps"
												/>
												<img
													className={styles.stampImage}
													src={greenstamp2}
													alt="bio-stamps"
												/>
											</div>
										) : null}
									</Col>
									{/* <Col md={6} className="d-none d-md-flex"> */}
									{/* <div className={styles.headerVisuals}>{img}</div> */}
									{/* </Col> */}
								</Row>
							</Container>
							{/* <p className={styles.titletop}>
                Title Name
            </p>
            <h1 className={styles.title}>
            Click to see live demonstration!
            </h1>
            <p className={styles.text}>
                Button here for watch video 
            </p>
        </div>
        <div className={styles.stamps}>
        <img
            className={styles.stampImage}
                       src={greenstamp1}
                       alt="stamp1"
                     />
                      <img
            className={styles.stampImage2}
                       src={greenstamp2}
                       alt="stamp1"
                     />
          </div> */}
						</div>
					</div>
				</section>
			</>

			// <div className={styles.header}>
			//   {this.props.pageTitle === "cosmetic-line" ? (
			//     <img
			//       className={styles.cosmeticHeader}
			//       src={cosmetic}
			//       alt="cosmetic"
			//     />
			//   ) : (
			//     <div className={`${styles.headerBackground}`}></div>
			//   )}
			//   {this.props.pageTitle === "cosmetic-line" ? null : (
			//     <Container fluid>
			//       <Row className="py-3">
			//         <Col xs={12} md={6} className={styles.headerHero}>
			//           <h1>{this.props.pageTitle.toUpperCase().replace(/-/g, " ")}</h1>
			//           <hr />
			//           {this.props.pageTitle === "special-cleaners" ||
			//           this.props.showButton ? (
			//             this.state.visible ? (
			//               <ScrollTrigger onExit={this.onExitViewport}>
			//                 <Tooltip
			//                   defaultVisible={true}
			//                   placement="left"
			//                   overlay={
			//                     <div className={styles.tooltip}>
			//                       Click to see live demonstration!
			//                     </div>
			//                   }
			//                 >
			//                   <button
			//                     className={styles.button}
			//                     onClick={this.props.changeVideoMode}
			//                   >
			//                     Watch Video
			//                   </button>
			//                 </Tooltip>
			//               </ScrollTrigger>
			//             ) : (
			//               <button
			//                 className={styles.button}
			//                 onClick={this.props.changeVideoMode}
			//               >
			//                 Watch Video
			//               </button>
			//             )
			//           ) : null}
			//           {this.props.pageTitle === "aircraft-cleaners" ||
			//           this.props.match.params.section === "aircraft-cleaners" ? (
			//             <div className={styles.stampsContainer}>
			//               {" "}
			//               <img
			//                 className={styles.stampImage}
			//                 src={stamp1}
			//                 alt="stamp1"
			//               />
			//               <img
			//                 className={styles.stampImage}
			//                 src={stamp2}
			//                 alt="stamp2"
			//               />
			//             </div>
			//           ) : null}
			//           {this.props.match.params.section !== "cosmetic-line" ? (
			//             <img
			//               className={styles.bioStamps}
			//               src={bioStamps}
			//               alt="bio-stamps"
			//             />
			//           ) : null}
			//         </Col>
			//         <Col md={6} className="d-none d-md-flex">
			//           <div className={styles.headerVisuals}>{img}</div>
			//         </Col>
			//       </Row>
			//     </Container>
			//   )}
			// </div>
		);
	}
}

export default withRouter(ProductsHeader);
