import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Components/Header/header";
import LandingPage from "./Components/LandingPage/landing-page.component";
import Specializations from "./Components/Specializations/specializations";
import AboutUs from "./Components/About/about";
import Footer from "./Components/Footer/footer";
import Contact from "./Components/Contact/Contact";
import Details from "./Components/Details/Details";
import Product from "./Components/Product/Product";
import Industry from "./Pages/Industry/Industry";
import ScrollToTop from "./Components/ScrollToTop";
import ContactUS from "./Components/contact-us/contact.us"
import "./App.css";
import ReactGA from 'react-ga';

function App() {

	useEffect(() => {
		ReactGA.initialize('UA-210324179-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
	});

	return (
		<BrowserRouter>
			<div className="main">
				<div className="main-wrapper">
					<Header />
					<ScrollToTop>
						<Switch>
							<Route path="/about" component={AboutUs} />
							<Route path="/specializations" component={Specializations} />
							<Route path="/contact" component={Contact} />
							{/* <Route path="/ContactUS" component={ContactUS} /> */}
							<Route exact path="/aircraft-cleaners" component={Details} />
							<Route exact path="/rail-cleaners" component={Details} />
							<Route exact path="/industry-cleaners" component={Details} />
							<Route exact path="/cosmetic-line" component={Details} />
							<Route exact path="/weapon-cleaners" component={Details} />
							<Route exact path="/special-cleaners" component={Details} />
							<Route exact path="/aviation-industry" component={Industry} />
							<Route exact path="/oil-industry" component={Industry} />
							<Route exact path="/rail-industry" component={Industry} />
							<Route exact path="/maritime-industry" component={Industry} />
							<Route exact path="/weapons-industry" component={Industry} />
							<Route exact path="/:section/:id" component={Product} />
							<Route exact path="/">
								<LandingPage />
							</Route>
						</Switch>
					</ScrollToTop>
				</div>
				<ContactUS />
				<Footer />
			</div>
		</BrowserRouter>
	);
}

export default App;
