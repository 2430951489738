import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, ListGroup, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import aviationIndustry from "../../images/aviation-industry-page.jpg";
import oilIndustry from "../../images/oil-industry-page.jpg";
import maritimeIndustry from "../../images/maritime-industry.jpg";
import railIndustry from "../../images/rail-industry.jpg";
import weaponIndustry from "../../images/gun-industry-page.jpg";
import benefits from "../../images/benefits.jpg";
import { db } from "../../services/firebase";
import ClipLoader from "react-spinners/ClipLoader";
import { ClipboardCheck, CheckSquare } from "react-bootstrap-icons";
import who from "../../images/who-we-are.jpg";
import "./industry.css";
import ProductCard from "../../Components/ProductCard/ProductCard";

const Industry = (props) => {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    const fetchDb = (industryRef) => {
      db.ref(industryRef).on("value", (snapshot) => {
        let content = snapshot.val();
        content && setContent(content);
        setLoading(false);
      });
    };



    // if (props.location.pathname.substring(1) === "weapons-industry") {
    //   history.push("/weapon-industry");
    // }
    // if (props.location.pathname.substring(1) === "aviation-industry") {
    //   history.push("/aviation-industry");
    // }
    fetchDb(`Industries/${props.location.pathname.substring(1)}`);
  }, [props.location.pathname, history]);
  const title = props.location.pathname
    .substring(1)
    .replace(/-/g, " ")
    .toUpperCase();
  let banner = null;
  switch (props.location.pathname.substring(1)) {
    case "aviation-industry":
      banner = <Image src={aviationIndustry} alt="banner" className="banner" />;
      break;
    case "oil-industry":
      banner = <Image src={oilIndustry} alt="banner" className="banner" />;
      break;
    case "maritime-industry":
      banner = <Image src={maritimeIndustry} alt="banner" className="banner" />;
      break;
    case "rail-industry":
      banner = <Image src={railIndustry} alt="banner" className="banner" />;
      break;
    case "weapons-industry":
      banner = <Image src={weaponIndustry} alt="banner" className="banner" />;
      break;
    default:
      break;
  }

  const handleLearnMoreButtonClicked = () => {
    switch (props.location.pathname.substring(1)) {
      case "aviation-industry":
        history.push("/aircraft-cleaners");
        break;
      case "oil-industry":
        history.push("/industry-cleaners");
        break;
      case "maritime-industry":
        history.push("/industry-cleaners");
        break;
      case "rail-industry":
        history.push("/rail-cleaners");
        break;
      case "weapons-industry":
        history.push("/weapon-cleaners");
        break;
      default:
        break;
    }

  }

  const switchProducts = (<>
    {content && content.products &&
      Object.keys(content.products).map((product) => {
        return (<>
          < Col xs={12} md={6} lg={4} >
            <ProductCard
              key={content.products[product].title}
              title={content.products[product].title}
              description={content.products[product].description}
              recommended={content.products[product].title === 'galaxy-100' ? true : false}
            />
          </Col>
        </>)
      })
    }
  </>)


  return (
    <Container className="industry-container" fluid>
      <Row>
        <Col>
          {banner}
          <h1 className="text-center title display-1 text-white">{title}</h1>
        </Col>
      </Row>
      {!isLoading ? (
        <>
          <Row>
            <Col>
              <p className="lead p-4 jumbotron">{content.description}</p>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col>
                <h1 className="d-flex bg-dark text-white justify-content-center align-items-center display-5 my-5">
                  Applications
                </h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Image src={who} className="images" />
              </Col>
              <Col xs={12} md={6}>
                <ListGroup variant="flush">
                  {content &&
                    Object.keys(content.applications).map((listItem) => (
                      <ListGroup.Item
                        key={listItem}
                        className="d-flex align-items-center"
                      >
                        <div>
                          <CheckSquare
                            color="black"
                            className="px-2"
                            size={50}
                          />
                        </div>
                        <div>{content.applications[listItem]}</div>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
            </Row>
            <Col lg={7}></Col>
            <Col >
              <Button
                variant="outline-success"
                size="lg"
                block
                onClick={() => handleLearnMoreButtonClicked()}
                className="w-50 mx-5 float-right"
              >
                LEARN MORE
              </Button>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col>
                <h1 className="d-flex bg-dark text-white justify-content-center align-items-center display-5 my-5">
                  Benefits
                </h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroup variant="flush">
                  {content &&
                    Object.keys(content.benefits).map((listItem) => (
                      <ListGroup.Item
                        key={listItem}
                        className="d-flex align-items-center"
                      >
                        <div>
                          <ClipboardCheck
                            color="black"
                            size={50}
                            className="px-2"
                          />
                        </div>
                        <div>{content.benefits[listItem]}</div>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
              <Col md={6} xs={12}>
                <Image src={benefits} className="images" />
              </Col>
            </Row>
          </Row>
          <Row className="g-4 mt-5 mx-auto">
            {switchProducts ? switchProducts : null}
          </Row>
          {props.location.pathname.substring(1) === 'weapons-industry' ? <Row>
            <Col className="d-flex justify-content-center">
              <Button
                variant="outline-success"
                onClick={() =>
                  history.push("weapon-cleaners/Galaxy-700")
                }
              >
                LEARN MORE
              </Button>
            </Col>
          </Row> : null}

        </>
      ) : (
        <ClipLoader
          css={{ display: "block", margin: "0 auto" }}
          size={150}
          color={"#123abc"}
          loading={isLoading}
        />
      )
      }
    </Container >
  );
};

export default Industry;
