import React, { Component } from "react";
import { GeoAlt, Envelope, Telephone } from "react-bootstrap-icons";
import logo from "../../images/footer-logo.png";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import "./contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from "react-router"

class ContactUS extends Component {

	constructor(props) {
		super(props);
		this.state = {
			from_name_first: "",
			from_name_last: "",
			message: "",
			number: "",
			reply_to: "",
			validatedRecaptcha: false
		};

	};


	componentDidMount() {
		init("user_HmK0C2qlf9tlHG6DtssuC");
	}


	submitHandler = (event, props) => {
		event.preventDefault();
		emailjs.send("Nadi1990!", "template_l0lr0ta", this.state).then(
			function (response) {
				console.log("SUCCESS!", response.status, response.text);
				props.history.push('/');
			},
			function (error) {
				console.log("FAILED...", error);
			}
		);
	};

	handleRecaptchaClicked = () => {
		this.setState({ validatedRecaptcha: true });
	}

	onChangeHandler = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.id;

		this.setState({ [name]: value });
	};

	render() {
		return (
			<>
				<div className="fluid-container mt-5">
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-8 mb-md-0 mb-5">
								<h3 className="heading">
									<u>CONTACT US</u>
								</h3>
								<h3 className="heading">Ready to get started?</h3>
								<form onSubmit={(event) => this.submitHandler(event, this.props)}>
									{/* <div className="row">
            <div
              className="form-group col-xl-12 col-lg-3 col-md-4 col-sm-12 col-xs-12"
            >
              <input
                type="text"
                className="form-control"
                placeholder="How we can help you"
              />
            </div>
          </div> */}
									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<input
													id="from_name_first"
													onChange={(event) => this.onChangeHandler(event)}
													type="text"
													className="form-control"
													placeholder="First Name"
												/>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<input
													id="from_name_last"
													onChange={(event) => this.onChangeHandler(event)}
													type="text"
													className="form-control"
													placeholder="Last Name"
												/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-xl-12">
											<div className="form-group">
												<input
													id="reply_to"
													onChange={(event) => this.onChangeHandler(event)}
													type="email"
													className="form-control"
													placeholder="Email"
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<input
													id="number"
													onChange={(event) => this.onChangeHandler(event)}
													type="text"
													className="form-control"
													placeholder="Mobile Number"
												/>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
											<div className="form-group">
												<input
													id="company"
													onChange={(event) => this.onChangeHandler(event)}
													type="text"
													className="form-control"
													placeholder="Company"
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-xl-12 col-lg-3 col-md-4 col-sm-12 col-xs-12">
											<textarea
												id="message"
												onChange={(event) => this.onChangeHandler(event)}
												className="form-control"
												placeholder="Your Message Here"
												rows="6"
											></textarea>
										</div>
									</div>

									{/* <div className="box form-check mb-3">
            <input className="form-check-input" type="checkbox" />
            <label className="terms form-check-label"
              >I accept all the Policies and Rules</label
            >
          </div> */}
									<div className="row justify-content-center align-items-center">
										<div className="note col mb-3 recaptcha">
											<ReCAPTCHA
												sitekey="6LeTfNQcAAAAAJtTqbFTnbRCHzmr5xIz8QX8PbhL"
												onChange={this.handleRecaptchaClicked}
											/>
										</div>
										<div className="col-3 col-xs-3 col-sm-2 p-0">
											<button
												className="btn btn-outline-light"
												type="submit"
												value="SEND"
												disabled={!this.state.validatedRecaptcha}
											>
												<strong>SEND</strong>
											</button>
										</div>


									</div>
								</form>
								<br />
							</div>
							<div className="manager col-12 col-lg-4 align-self-center">
								<div className="row">
									{/* <div className="image col-auto align-self-center">
            <img src="assets/dummyGirl.jpg" alt="Avatar" />
          </div> */}
									<div className="details col align-self-center">
										<h3>
											<span>
												<GeoAlt color="white" />
											</span>
											<span>
												Yigal Alon 40 St
												<br /> Herzliya
												<br /> Israel
											</span>
										</h3>
										<h5>
											<span>
												<Envelope color="white" />
											</span>
											<span>info@kacgalaxy.com</span>
										</h5>

										<p>
											<span>
												<Telephone color="white" />
											</span>
											<span>+972 53-940-6985</span>
										</p>
									</div>

									{/* <h3 className="heading-social text-center">Social Media</h3>
          <div
            className="
              social-icons
              col
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <a href="https://www.facebook.com/GlobalXTech/" target="_blank"
              ><img src="assets/facebook.png" alt="Avatar"
            /></a>
            <a href="#"><img src="assets/insta.png" alt="Avatar" /></a>
            <a href="#"><img src="assets/linkedin.png" alt="Avatar" /></a>
            <a
              href="https://www.youtube.com/channel/UC2yelKvCGssQOWjnfDNcQOA"
              target="_blank"
              ><img src="assets/youtube.png" alt="Avatar"
            /></a>
          </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(ContactUS);
