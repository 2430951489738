// import React, { Component } from "react";

// import { GeoAlt, Envelope, Telephone } from "react-bootstrap-icons";
// import logo from "../../images/footer-logo.png";
// import emailjs from "emailjs-com";
// import { init } from "emailjs-com";

// class Contact extends Component {
//   state = {
//     from_name_first: "",
//     from_name_last: "",
//     message: "",
//     number: "",
//     reply_to: "",
//   };

//   componentDidMount() {
//     init("user_HmK0C2qlf9tlHG6DtssuC");
//   }

//   submitHandler = () => {
//     emailjs.send("Nadi1990!", "template_l0lr0ta", this.state).then(
//       function (response) {
//         console.log("SUCCESS!", response.status, response.text);
//       },
//       function (error) {
//         console.log("FAILED...", error);
//       }
//     );
//   };

//   onChangeHandler = (event) => {
//     const target = event.target;
//     const value = target.value;
//     const name = target.name;

//     this.setState({ [name]: value });
//   };

//   render() {
//     return (
//       <>
//         <section className={styles.contactSection}>
//           <div className={styles.container}>
//             <div className={styles.contactInfo}>
//               <div>
//                 <h2>Contact Info</h2>
//                 <ul className={styles.info}>
//                   <li>
//                     <span>
//                       <GeoAlt color="white" />
//                     </span>
//                     <span>
//                       Yigal Alon 40 St
//                       <br /> Herzliya
//                       <br /> Israel
//                     </span>
//                   </li>
//                   <li>
//                     <span>
//                       <Envelope color="white" />
//                     </span>
//                     <span>info@kacgalaxy.com</span>
//                   </li>
//                   <li>
//                     <span>
//                       <Telephone color="white" />
//                     </span>
//                     <span>+972 53-940-6985</span>
//                   </li>
//                 </ul>
//               </div>
//               <img src={logo} alt="logo" className={styles.logo} />
//             </div>
//             <div className={styles.contactForm}>
//               <h2>Send a Message</h2>
//               <div className={styles.formBox}>
//                 <div className={`${styles.inputBox} ${styles.w50}`}>
//                   <input
//                     onChange={(event) => this.onChangeHandler(event)}
//                     type="text"
//                     name="from_name_first"
//                     required
//                   />
//                   <span>First Name</span>
//                 </div>
//                 <div className={`${styles.inputBox} ${styles.w50}`}>
//                   <input
//                     onChange={(event) => this.onChangeHandler(event)}
//                     type="text"
//                     name="from_name_last"
//                     required
//                   />
//                   <span>Last Name</span>
//                 </div>
//                 <div className={`${styles.inputBox} ${styles.w50}`}>
//                   <input
//                     onChange={(event) => this.onChangeHandler(event)}
//                     type="text"
//                     name="reply_to"
//                     required
//                   />
//                   <span>Email Address</span>
//                 </div>
//                 <div className={`${styles.inputBox} ${styles.w50}`}>
//                   <input
//                     onChange={(event) => this.onChangeHandler(event)}
//                     type="text"
//                     name="number"
//                     required
//                   />
//                   <span>Mobile Number</span>
//                 </div>
//                 <div className={`${styles.inputBox} ${styles.w100}`}>
//                   <textarea
//                     onChange={(event) => this.onChangeHandler(event)}
//                     name="message"
//                     required
//                   ></textarea>
//                   <span>Write your message here...</span>
//                 </div>
//                 <div className={`${styles.inputBox} ${styles.w100}`}>
//                   <input
//                     onClick={this.submitHandler}
//                     type="submit"
//                     value="SEND"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </>
//     );
//   }
// }

// export default Contact;

import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Form } from 'react-bootstrap';
import styles from "./contact.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

//   state = {
//     from_name_first: "",
//     from_name_last: "",
//     message: "",
//     number: "",
//     reply_to: "",

function Contact() {
  const [validatedRecaptcha, setValidatedRecaptcha] = useState(false);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    init("user_HmK0C2qlf9tlHG6DtssuC");
  }, []);

  const handleRecaptchaClicked = () => {
    setValidatedRecaptcha(true);
  }

  const onChangeHandler = (event) => {
    const value = event.target.value;
    const id = event.target.id;

    setValues({ ...values, [id]: value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    emailjs.send("Nadi1990!", "template_l0lr0ta", values).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        setLoading(false);
        history.push("/");
      },
      function (error) {
        console.log("FAILED...", error);
        setLoading(false);
      }
    );
  };

  return (
    <section className={styles.contact}>
      <Container className={`py-5 ${styles.contactContainer}`}>
        {!loading ? (<Row>
          <Col lg={6}>
            <Card className={`${styles.contactCard}`}>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                    <div className={`${styles.head} text-center text-white py-3`}>
                      <h3>Contact Us</h3>
                    </div>
                  </Col>
                </Row>
                <Form className="p-3" onSubmit={(event) => submitHandler(event)}>
                  <Row className="my-5">
                    <Col lg={6}>
                      <Form.Group controlId="from_name_first">
                        <Form.Control type="text" className={`${styles.effect1}`} placeholder="First Name" onChange={onChangeHandler} />
                        <span className={`${styles.focusBorder1}`}></span>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="from_name_last">
                        <Form.Control type="text" className={`${styles.effect1}`} placeholder="Last Name" onChange={onChangeHandler} />
                        <span className={`${styles.focusBorder1}`}></span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-5">
                    <Col lg={12}>
                      <Form.Group controlId="reply_to">
                        <Form.Control type="email" className={`${styles.effect1}`} placeholder="Email Address" onChange={onChangeHandler} />
                        <span className={`${styles.focusBorder1}`}></span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="number">
                        <Form.Control type="phone" className={`${styles.effect1}`} placeholder="Mobile Number" onChange={onChangeHandler} />
                        <span className={`${styles.focusBorder1}`}></span>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="company">
                        <Form.Control type="text" className={`${styles.effect1}`} placeholder="Company" onChange={onChangeHandler} />
                        <span className={`${styles.focusBorder1}`}></span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    <Col lg={12}>
                      <Form.Group controlId="message">
                        <Form.Control as="textarea" rows={3} className={`${styles.effect1}`} placeholder="Your Message" onChange={onChangeHandler} />
                        <span className={`${styles.focusBorder1}`}></span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col lg={6} md={12} xs={12} className={`mb-3 ${styles.recaptcha}`}>
                      {/* secret-key:6Ld77LYcAAAAAGPHM1AhOuKuGImIfzqxHt4UsUf1
                      sitekey: 6Ld77LYcAAAAAOEdu_xj8NDWbTrpRAiEiO7YT9uT */}
                      <ReCAPTCHA
                        sitekey="6LeTfNQcAAAAAJtTqbFTnbRCHzmr5xIz8QX8PbhL"
                        onChange={handleRecaptchaClicked}
                      />
                    </Col>
                    <Col lg={4} className={`offset-2`}>
                      <button type="submit" className={`${styles.btn1} ${!validatedRecaptcha ? styles.disabledButton : null}`} disabled={!validatedRecaptcha}>SEND MESSAGE</button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>) : null}

        <ClipLoader
          css={{
            display: "block",
            margin: "0 auto",
          }}
          size={100}
          color={"#123abc"}
          loading={loading}
        />
      </Container>
    </section>
  )
}

export default Contact
