import React, { Component } from "react";
import styles from "./details.module.css";
import aircraft from "../../images/aircraft.png";
import rail from "../../images/rail.png";
import industry from "../../images/industry.png";
import special from "../../images/special.png";
import cosmetic from "../../images/cosmetic-line.webp";
import ProductCard from "../ProductCard/ProductCard";
import { db } from "../../services/firebase";
import ProductHeader from "../ProductsHeader/ProductsHeader";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import ClipLoader from "react-spinners/ClipLoader";
import promp from "../../images/prom.png";
import { Button, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";

class Specializations extends Component {
	state = {
		products: null,
		description: "",
		pageTitle: "",
		videoMode: false,
		loading: true,
	};

	componentDidMount() {
		let title = this.props.location.pathname.substring(1);
		this.setState({
			pageTitle: title,
			showPromp: false,
		});

		switch (title) {
			case "aircraft-cleaners":
				this.fetchDb("/aircraft-products", "/aircraft");
				break;
			case "rail-cleaners":
				this.fetchDb("/rail-products", "/rail");
				break;
			case "industry-cleaners":
				this.fetchDb("/industry-products", "/industry");
				break;
			case "special-cleaners":
				this.fetchDb("/special-products", "/special");
				break;
			case "cosmetic-line":
				this.fetchDb("/cosmetic-products", "/cosmetic");
				break;
			case "weapon-cleaners":
				this.fetchDb("/weapon-products", "/Weapon");
				this.setState({ showPromp: true });
				break;
			default:
		}
	}

	fetchDb = (refProducts, ref) => {
		db.ref(refProducts).on("value", (snapshot) => {
			let productObject = snapshot.val();
			const productList = Object.keys(productObject).map((key) => ({
				...productObject[key],
			}));
			this.setState(
				{
					products: productList,
				},
				() => this.setState({ loading: false })
			);
		});

		db.ref(ref).on("value", (snapshot) => {
			let description = snapshot.val();

			this.setState({
				description: description["description"],
			});
		});
	};

	changeVideoMode = () => {
		this.setState({ videoMode: !this.state.videoMode });
	};

	render() {
		let img = null;

		switch (this.state.pageTitle) {
			case "aircraft-cleaners":
				img = (
					<img className={styles.headerImage} src={aircraft} alt="aircraft" />
				);
				break;
			case "rail-cleaners":
				img = <img className={styles.headerImage} src={rail} alt="rail" />;
				break;
			case "industry-cleaners":
				img = (
					<img
						className={`${styles.headerImage} ${styles.industry}`}
						src={industry}
						alt="industry"
					/>
				);
				break;
			case "special-cleaners":
				img = (
					<img
						className={`${styles.headerImage} ${styles.special} ${styles.industry} `}
						src={special}
						alt="special"
					/>
				);
				break;
			case "cosmetic-line":
				img = (
					<img
						className={`${styles.headerImage} ${styles.special} ${styles.industry} `}
						src={cosmetic}
						alt="special"
					/>
				);
				break;
			default:
		}

		return (
			<>
				<Modal
					show={this.state.showPromp}
					onHide={() => this.setState({ showPromp: false })}
					keyboard={true}
					dialogClassName={styles.prompModal}
					fullscreen
					centered
					size="lg"
				>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<img
							src={promp}
							className={styles.prompImage}
							alt="promotion banner"
						/>
					</Modal.Body>
				</Modal>
				<Modal
					show={this.state.videoMode}
					onHide={this.changeVideoMode}
					keyboard={true}
					dialogClassName={styles.videoModal}
				>
					<Modal.Header closeButton></Modal.Header>
					<ReactPlayer url="https://vimeo.com/436044281" width="100%" />
				</Modal>
				<section className="container-fluid">
					<ProductHeader
						className={styles.ProductHeader}
						pageTitle={this.state.pageTitle}
						changeVideoMode={this.changeVideoMode}
					/>
					<p className={`${styles.paragraph} p-4`}>{this.state.description}</p>
					<div className="my-5 container">
						<Row xs={1} md={2} lg={3} className="g-4">
							{this.state.products
								? this.state.products.map((product) => (
										<Col>
											<ProductCard
												key={product.title}
												title={product.title}
												description={product.description}
												recommended={product.recommended}
											/>
										</Col>
								  ))
								: null}
						</Row>
					</div>
					{this.props.history.location.pathname.includes("weapon-cleaners") && (
						<Row>
							<Col className="d-flex justify-content-center">
								<Button
									variant="outline-success"
									onClick={() =>
										this.props.history.push("weapon-cleaners/Galaxy-700")
									}
								>
									LEARN MORE
								</Button>
							</Col>
						</Row>
					)}

					<ClipLoader
						css={{ display: "block", margin: "0 auto" }}
						size={150}
						color={"#123abc"}
						loading={this.state.loading}
					/>
				</section>
			</>
		);
	}
}

export default withRouter(Specializations);

/*Galaxy-18/200/1000*/
