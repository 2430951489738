import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import aboutImg from "../../images/about.png";
import what from "../../images/what-we-do.jpg";
import "./about.css";
const Aboutus = () => {
	function handleScroll() {
		window.scroll({
			top: document.body.offsetHeight,
			left: 0,
			behavior: "smooth",
		});
	}

	return (
		<Container>
			<div>
				<div>
					<div>
						<h5 className="heading featuredHeadline projectsHeadline">
							A FEW WORDS ABOUT US
						</h5>
					</div>
				</div>
				<div className="clearfix">
					<img src={aboutImg} alt="aboutImg" className="aboutImg" />
					<div>
						<p>
							In the midst of ever-increasing environmental crisis facing the
							world due to toxic and dangerous chemical, Green Galaxy offers a
							wide range of worksafe and environmental friendly chemicals.
							Driven by the passion to provide a sustainable solution without
							sacrificing environmental wellness and safety, our line of
							products is formulated to protect water, air and the environment
							as a whole. In 2017, motivated by the zeal to the use of toxic
							chemicals and reduce the impact of industrial chemicals on the
							environment, Pini Kroyzman and Arie Liba set out on research to
							give the world better alternatives. The result is Green Galaxy, a
							company that specializes in innovative and eco-friendly cleaning
							chemicals. We’ve come a long way since our establishment, and our
							dedication and passion remains unflinching.
						</p>
						<p>
							Today, we work with both local and global companies, delivering
							our innovative solutions that are created with the highest degree
							of precision and care. <br />
							<br /> If you are interested on any of our products or want to
							learn more, kindly send us your questions and comment and we’ll
							get back with details
						</p>
						<Link className="btn btn-outline-success" onClick={handleScroll}>
							Contact Us
						</Link>
					</div>
				</div>
			</div>
			<Row>
				<Col>
					<h5 className="featuredHeadline projectsHeadline">Our Goals</h5>
				</Col>
			</Row>
			<Row>
				<Col className="missionList">
					{/* <li>
            <h5>MISSION, VISION & VALUES</h5>
          </li> */}

					<Col className="missionItem">
						<div className="circle bg-success">1</div>
						<div className="pContainer">
							<h6 className="missionItemHeadline">MISSION</h6>
							<p className="missionParagraph">
								Build the best product, cause no unnecessary harm, use business
								to inspire and implement solutions to the environmental crisis.
							</p>
						</div>
					</Col>
					<Col className="missionItem">
						<div className="circle bg-success">2</div>
						<div className="pContainer">
							<h6 className="missionItemHeadline">VISION</h6>
							<p className="missionParagraph">
								Striving to protect and sustain our environment is a given at
								every stage of our product lifecycles, from development and
								production to customer use and disposal.
							</p>
						</div>
					</Col>
					<Col className="missionItem">
						<div className="circle bg-success">3</div>
						<div className="pContainer">
							<h6 className="missionItemHeadline">VALUES</h6>
							<p className="missionParagraph">
								We provide outstanding products and unsurpassed service that,
								together, deliver premium value to our customers.
							</p>
						</div>
					</Col>
				</Col>
			</Row>

			<Row className="blockquoteWrapper">
				<div className="blockquote">
					<h1 className="text-success">
						Driven by the passion to provide a sustainable solution without
						sacrificing environmental wellness
					</h1>
					<h4 className="text-success">&mdash; Green Galaxy</h4>
				</div>
			</Row>

			<div className="clearfix">
				<h5 className="featuredHeadline projectsHeadline">What we do</h5>
				<div>
					<img src={what} alt="aboutImg" className="aboutImg" />
					<div className="aboutParagraphContainer">
						<p>
							Green Galaxy succeeds with innovative strategies, warranted
							products, and cost-effective services. We prioritize excellence
							and efficiency in all aspects of our work and specialize in
							offering cleaning resolutions for a variety of demands across
							numerous industries. Through our implementation of 100% green
							cleaning materials, we can advocate for workplace sanitization and
							earth safety. It is our passion to support a green initiative in
							the industrial space. We take pride in our environmentally safe
							cleaning products and chemicals that assist industries with their
							needs while considering the environment’s condition. Each of our
							products is designed with the earth’s sustainability in mind so
							both the workforce and the world can prosper.
						</p>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Aboutus;
