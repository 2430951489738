// import React from "react";
// import { NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";
// import logo from "../../images/Artboard 1LOGO.png";
// import chemicalSvg from "../../images/chemical.png"
// import { GeoAlt, Telephone, Envelope } from "react-bootstrap-icons";
// import "./header.css";
// const Header = () => {
//   return (
//     <>
//       <div className="header">
//         <Link to="/">
//           <img className="logo" src={logo} alt="logo" />
//         </Link>
//         <nav className="mainNav">
//           <ul className="mainNavContactInfoItems">
//             <li className="mainNavContactInfoItem mx-2">
//               <span className="px-1">
//                 <GeoAlt color="black" />
//               </span>
//               <span>Yigal Alon 40 St, Herzliya, Israel</span>
//             </li>
//             <li className="mainNavContactInfoItem mx-2">
//               <span className="px-1">
//                 <Envelope color="black" />
//               </span>
//               <span>
//                 <a href="mailto:info@kacgalaxy.com">info@kacgalaxy.com</a>
//               </span>
//             </li>
//             <li className="mainNavContactInfoItem mx-2">
//               <span className="px-1">
//                 <Telephone />
//               </span>
//               <span>+972 53-940-6985</span>
//             </li>
//           </ul>
//           <div className="navItemsContainer">
//             <img
//             className="imgChemical"
//             src={chemicalSvg}
//             alt="chemical"
//           />
//             <ul className="navItems">
//               <li className="navItem">
//                 <NavLink as={Link} exact to="/">
//                   Home
//                 </NavLink>
//               </li>
//               <li className="navItem">
//                 <NavLink as={Link} to="/about" activeClassName="active">
//                   About
//                 </NavLink>
//               </li>
//               <li className="navItem">
//                 <NavLink
//                   as={Link}
//                   to="/specializations"
//                   activeClassName="active"
//                 >
//                   Specializations
//                 </NavLink>
//               </li>
//               <li className="navItem">
//                 <NavLink as={Link} to="/contact" activeClassName="active">
//                   Contact Us
//                 </NavLink>
//               </li>
//             </ul>
//           </div>
//         </nav>
//       </div>
//     </>
//   );
// };

// export default Header;

import React from "react";
import logo from "../../images/Artboard 1LOGO.png";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./header.css";
const Header = () => {
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-start">
            <Nav className="me-auto">
              {/* <img
            className="imgChemical"
            src={chemicalSvg}
            alt="chemical"
          /> */}
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/specializations">Our Products</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
            </Nav>

          </Navbar.Collapse>
          <Navbar.Brand href="/">
            <img className="logo" src={logo} alt="logo" />
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
