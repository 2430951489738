import React from "react";
import packingIcon from "../../../../images/packingIcon.jpg";
import styles from "./packageSection.module.css";
import PackCard from "./packCard/PackCard";
import { Container, Row, Col } from "react-bootstrap";

const PackageSection = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={styles.textHeadlineContainer}>
            <div className={`${styles.textHeadline}`}>
              <div className={styles.circle}>
                <img src={packingIcon} alt="packing-icon" />
              </div>
              <h1>packing</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className={styles.packingItems}>
        <Col xs={12} md={6} xl={4} className={styles.packingItem}>
          <PackCard {...props} type="small" />
        </Col>
        <Col xs={12} md={6} xl={4} className={styles.packingItem}>
          <PackCard {...props} type="medium" />
        </Col>
        <Col xs={12} md={6} xl={4} className={styles.packingItem}>
          <PackCard {...props} type="big" />
        </Col>
      </Row>
    </Container>
  );
};

export default PackageSection;
