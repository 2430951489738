import React, { Component } from "react";
import styles from "./productCard.module.css";
import containers from "../../images/containers.png";
import clean19 from "../../images/clean19.png";
import cream from "../../images/cream.png";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import images4 from "../../images/images4.jpg";
import { withRouter } from "react-router-dom";
import { Card, Button, Row, Col } from "react-bootstrap";

class ProductCard extends Component {
	navigateToProduct = () => {
		this.props.history.push(
			this.props.location.pathname + "/" + this.props.title
		);
	};

	render() {
		let img = null;
		switch (this.props.title.toLowerCase()) {
			case "galaxy-clean-19":
				img = clean19;
				break;
			case "galaxy-antibacterial-hand-cream":
				img = cream;
				break;
			case "gun lube spray(125)":
				img = image2;
				break;
			case "gun lube spray(500)":
				img = image1;
				break;
			case "gun lube wipes":
				img = image3;
				break;
			case "galaxy-18/200/1000":
				img = images4;
				break;

			default:
				img = containers;
		}

		return (
			<>

				{this.props.history.location.pathname.includes("weapon-cleaners") ||
					this.props.history.location.pathname.includes("weapons-industry") ? (
					<>
						<Card
							border="success"
							className="h-100 position-relative"
							style={{ overflow: "hidden" }}
						>
							{this.props.recommended ? (
								<div className={styles.ribbon}>RECOMMENDED</div>
							) : null}
							<Card.Img variant="top" src={img} className={styles.cardPhoto} />
							<Card.Body>
								<Card.Title className="text-center">
									{this.props.title.toUpperCase().replace(/-/g, " ")}
								</Card.Title>
								<Card.Text>{this.props.description}</Card.Text>
							</Card.Body>
						</Card>
					</>
				) : (
					<>
						<Card
							border="success"
							className="h-100 position-relative"
							style={{ overflow: "hidden" }}
						>
							{this.props.recommended ? (
								<div className={styles.ribbon}>RECOMMENDED</div>
							) : null}
							<Card.Img variant="top" src={img} className={styles.cardPhoto} />
							<Card.Body>
								<Card.Title className="text-center">
									{this.props.title.toUpperCase().replace(/-/g, " ")}
								</Card.Title>
								<Card.Text>{this.props.description}</Card.Text>
							</Card.Body>
							<Card.Footer>
								<Button
									size="lg"
									block
									variant="outline-success"
									// className="button width"
									onClick={this.navigateToProduct}
								>
									LEARN MORE
								</Button>
							</Card.Footer>
						</Card>
					</>

					// <section className={styles.product}>
					// 	{this.props.recommended ? (
					// 		<div className={styles.ribbon}>RECOMMENDED</div>
					// 	) : null}

					// 	<div className={styles.productPhoto}>
					// 		<div className={styles.photoContainer}>
					// 			<div className={styles.photoMain}>{img}</div>
					// 		</div>
					// 	</div>
					// 	<div className={styles.productInfo}>
					// 		<div>
					// 			<div className={styles.title}>
					// 				<h1>{this.props.title.toUpperCase().replace(/-/g, " ")}</h1>
					// 				<span>COD: 45999</span>
					// 			</div>
					// 			<div className={styles.variant}>
					// 				<h3>DESCRIPTION</h3>
					// 				<div className={styles.desc}>{this.props.description}</div>
					// 			</div>
					// 		</div>
					// 		<button className="button width" onClick={this.navigateToProduct}>
					// 			LEARN MORE
					// 		</button>
					// 	</div>
					// </section>
				)}
			</>
		);
	}
}

export default withRouter(ProductCard);
