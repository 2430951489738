import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import fieldImg from "../../images/field-ge8fb8cdc2_1920.jpg";
import fieldImg from "../../images/green-galaxy-landing.jpg";
import industry from "../../images/industry-environment.jpg";
import industry2 from "../../images/green-industry.jpg";
import who from "../../images/who-we-are.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import sustain1 from "../../images/sustain1.png";
import sustain2 from "../../images/sustain2.png";
import sustain3 from "../../images/sustain3.png";
import ReactPlayer from "react-player";
import arkia from "../../images/arkia.png";
import cleanOil from "../../images/cleanOil.png";
import cleanPlane from "../../images/cleanPlane.png";
import cleanContainer from "../../images/cleanContainer.png";
import graffitiRemoval from "../../images/graffitiRemoval.png";
import oilRemoval from "../../images/oilRemoval.png";
import FeaturedProductCard from "../FeaturedProductCard/FeaturedProductCard";
import { db } from "../../services/firebase";
// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import whiteLogo from "../../images/logo white.png";
import railCleaners from "../../images/rail-industry-2.jpg";
import industryCleaners from "../../images/oil-industry-green.jpg";
import aircrafCleaners from "../../images/airplane-fuel.jpg";
import specialCleaners from "../../images/maitime-industry.jpg";
import engineBath from "../../images/enginebath.jpg";
import weaponCleaning from "../../images/weapon-cleaning.jpg";
import weapons from "../../images/gun-industry.jpg";
import "./landing-page.css";

const LandingPage = () => {
	const [loading, setLoading] = useState(true);
	const [featuredProducts, setFeaturedProducts] = useState([]);
	useEffect(() => {
		const fetchDb = () => {
			db.ref("/featured").on("value", (snapshot) => {
				let productsObject = snapshot.val();
				const productsList = Object.keys(productsObject).map((key) => ({
					...productsObject[key],
				}));
				setFeaturedProducts(productsList);
				setLoading(false);
			});
		};
		fetchDb();
	}, []);

	return (
		<>
			<div className="container-fluid">
				<section className="carouselContainer">
					<Carousel
						showThumbs={false}
						showStatus={false}
						autoPlay={true}
						infiniteLoop={true}
						interval={7000}
					>
						<img className="slide-img" src={fieldImg} alt="industry" />

						<img className="slide-img" src={industry2} alt="industry2" />
					</Carousel>
					<div className="headlineContainer">
						<div className="caroselHeadline">
							<img src={whiteLogo} alt="logo2" />
							<div className="vl"></div>
							{/* <h1>
            POWERFUL ECO-FRIENDLY CLEANERS AND DEGREASERS
            <p>FOR INDUSTRIAL AND SPECIALIST APPLICATION</p>
          </h1> */}
							<h1>
								{/* Eco Friendly Cleaners And Degreasers */}
								<p>Your <span className="strokeme">Green</span> Solution</p>
							</h1>
						</div>
					</div>
				</section>
			</div>
			<main className="container">
				<Row className="ecoImgsItems">
					<Col className="ecoImgsItemGroup" xs={6} md={4} xl={3}>
						<img src={sustain1} alt="sustain" className="ecoImgsItem" />
						<h4 style={{ textTransform: "uppercase" }}>Eco-friendly</h4>
					</Col>
					<Col className="ecoImgsItemGroup" xs={6} md={4} xl={3}>
						<img src={sustain2} alt="sustain2" className="ecoImgsItem" />
						<h4 style={{ textTransform: "uppercase" }}>sustainable</h4>
					</Col>
					<Col className="ecoImgsItemGroup" xs={6} md={4} xl={3}>
						<img src={sustain3} alt="sustain3" className="ecoImgsItem" />
						<h4 style={{ textTransform: "uppercase" }}>Biodegradable</h4>
					</Col>
				</Row>
				<section>
					<h5 className="featuredHeadline">Who We Are</h5>
					<Row className="aboutContainer">
						<Col xs={12} md={12} xl={6}>
							<img src={who} alt="aboutImg" className="aboutImg_landing" />
						</Col>
						<Col>
							<Col xs={12} md={12} xl={12}>
								<p
									style={{
										marginTop: "20px",
									}}
									className=""
								>
									{/* K.A.C specializes in providing professional,high quality and
									efficient industrial cleaning.Through the use of
									environmentally and user-friendly materials, K.A.C services a
									broad spectrum of needs inindustry,including
									vehicles,trains,ships,roads,aircraft,weapons,cosmetcs and more.
									With vast knowledge,expertse and rich experience,K.A.C team of
									experts understand the best methods to characterize customer
									needs as the basis for building the proper and most effectve
									soluton.A soluton providing added value and resulitng in full
									satisfaction.Working under strict standards with advanced
									water-based,non-toxic and biodegradable materials,K.A.C is
									founded on environmental protection. */}
									K.A.C is a leading provider of professional, high quality and efficient industrial cleaning
									products. We specialize in environmentally and user-friendly materials, efficient for a broad
									spectrum of industries including: Aviation, Oil, Rail, Maritime, Weapons, Cosmetics and more.
									Green Galaxy is a unique line of cleaners and degreasers which has proven to be the world’s
									most advanced solution for industrial and institutional use. With vast knowledge and rich
									experience, our team understands today&#39;s customer needs and strives to find the proper and
									most effective solution, providing added value and full satisfaction.
								</p>
							</Col>
							<Col>
								{/* <h1>Specializations</h1> */}

								<h5 className="mb-3">Cleaning Products for Industrial & Institutional Purposes</h5>
								<p>
									{/* Green Galaxy offers the Galaxy line of cleaners and degreasers
									which are comprehensive benchmark of sustainable cleaning and has
									proven to be the world most advanced solution for industrial and
									institutional use.Powered by the love of nature and extensive
									research, our chemicals are breakthrough formula designed for
									efficiency and versatility using sustainable organic ingredients.
									As a certified and reputable company, the galaxy line of products
									is available in ultra-concentrated form to meet the eco-friendly
									industry demand and worldwide campaign. */}
									Green Galaxy is dedicated to environmental protection. We work under strict standards with
									advanced water-based, non-toxic and biodegradable materials. Powered by the love of nature
									and extensive research, our chemicals are ground-breaking formula designed for efficiency and
									versatility, using sustainable organic ingredients. The galaxy line of products is available in ultra-
									concentrated form to meet the eco-friendly industry demand and worldwide campaign.
								</p>
							</Col>
						</Col>
					</Row>
					{/* <Row> */}
					{/* <Col xs={12} md={12} xl={3}>
							<img src={who} alt="aboutImg" className="aboutImg_landing" />
						</Col> */}


					{/* </Row> */}
				</section>
				<section className="ecoFriendlyContainer">
					<div className="">
						<h5 className="featuredHeadline">featured products</h5>
						<ClipLoader
							css={{
								display: "block",
								margin: "0 auto",
							}}
							size={100}
							color={"#123abc"}
							loading={loading}
						/>
						<Row className="featuredProductsFlex g-4 mb-5">
							{featuredProducts ? (
								<>
									{featuredProducts.map((product) => {
										return (
											<Col key={product["title"]} xs={12} md={6} xl={3}>
												<FeaturedProductCard
													title={product["title"]}
													description={product["description"]}
												/>
											</Col>
										);
									})}
								</>
							) : null}
						</Row>
					</div>
					{/* <h2 className={styles.secondaryHeadline}>Welcome to green galaxy</h2> */}
					{/* <h5 className={styles.secondaryHeadline}>
            Your number one source of biodegradable chemicals and solution for
            manufacturing industries
          </h5> */}
				</section>
				<Row className="tvContainer">
					{/* <Col className="specializationText">
						<h1>Specializations</h1>
						<h5>Cleaning Products for Industrial & Institutional Purposes</h5>
						<p>
							Green Galaxy offers the Galaxy line of cleaners and degreasers
							which are comprehensive benchmark of sustainable cleaning and has
							proven to be the world most advanced solution for industrial and
							institutional use. Powered by the love of nature and extensive
							research, our chemicals are breakthrough formula designed for
							efficiency and versatility using sustainable organic ingredients.
							As a certified and reputable company, the galaxy line of products
							is available in ultra-concentrated form to meet the eco-friendly
							industry demand and worldwide campaign.
						</p>
					</Col> */}
					<Col xs={12} md={12} xl={12}>
						<div className="player-wrapper">
							<ReactPlayer
								url="https://vimeo.com/635395748"
								className="react-player"
								width="100%"
								height="36rem"
								controls={false}
							/>
						</div>
					</Col>
				</Row>
				<section>
					<h5 className="featuredHeadline mt-5 mb-5">
						Industries
					</h5>
				</section>
				<Row className="g-4">
					<Col xs={12} md={6} xl={4}>
						<Link
							to="/aviation-industry"
							className="text-decoration-none text-white"
						>
							<Card className="bg-dark text-white mx-2 industry-card">
								<Card.Img
									src={aircrafCleaners}
									alt="Card image"
									className="card-bg"
								/>
								<Card.ImgOverlay className="d-flex flex-column justify-content-center">
									<h2 className="text-center">Aviation Industry</h2>
								</Card.ImgOverlay>
							</Card>
						</Link>
					</Col>
					<Col xs={12} md={6} xl={4}>
						<Link
							to="/oil-industry"
							className="text-decoration-none text-white"
						>
							<Card className="bg-dark text-white mx-2 industry-card">
								<Card.Img
									src={industryCleaners}
									alt="Card image"
									className="card-bg"
								/>
								<Card.ImgOverlay className="d-flex flex-column justify-content-center">
									<h2 className="text-center">Oil Industry</h2>
								</Card.ImgOverlay>
							</Card>
						</Link>
					</Col>
					<Col xs={12} md={6} xl={4}>
						<Link
							to="/rail-industry"
							className="text-decoration-none text-white"
						>
							<Card className="bg-dark text-white mx-2 industry-card">
								<Card.Img
									src={railCleaners}
									alt="Card image"
									className="card-bg"
								/>
								<Card.ImgOverlay className="d-flex flex-column justify-content-center">
									<h2 className="text-center">Rail Industry</h2>
								</Card.ImgOverlay>
							</Card>
						</Link>
					</Col>
					<Col xs={12} md={6}>
						<Link
							to="/maritime-industry"
							className="text-decoration-none text-white"
						>
							<Card className="bg-dark text-white mx-2 industry-card">
								<Card.Img
									src={specialCleaners}
									alt="Card image"
									className="card-bg"
								/>
								<Card.ImgOverlay className="d-flex flex-column justify-content-center">
									<h2 className="text-center">Maritime Industry</h2>
								</Card.ImgOverlay>
							</Card>
						</Link>
					</Col>
					<Col xs={12} md={6}>
						<Link
							to="/weapons-industry"
							className="text-decoration-none text-white"
						>
							<Card className="bg-dark text-white mx-2 industry-card">
								<Card.Img src={weapons} alt="Card image" className="card-bg" />
								<Card.ImgOverlay className="d-flex flex-column justify-content-center">
									<h2 className="text-center">Weapons Industry</h2>
								</Card.ImgOverlay>
							</Card>
						</Link>
					</Col>
				</Row>
				<h5 className={`featuredHeadline mt-5`}>RECENT PROJECTS</h5>
				<section className="picturesGridContainer">
					<img
						src={cleanContainer}
						alt="cleanContainer"
						className="gridItem1"
					/>
					<img
						src={engineBath}
						alt="graffitiRemoval"
						className="gridItem2"
					/>
					<img src={cleanPlane} alt="cleanPlane" className="gridItem3" />
					<img src={weaponCleaning} alt="arkia" className="gridItem4" />
					<img src={oilRemoval} alt="oilRemoval" className="gridItem5" />
					<img src={cleanOil} alt="cleanOil" className="gridItem6" />
				</section>
			</main>
		</>
	);
};

export default LandingPage;
