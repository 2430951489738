import React, { Component } from "react";
import styles from "./product.module.css";
import ProductHeader from "../ProductsHeader/ProductsHeader";
import { db } from "../../services/firebase";
import Section from "./Section/Section";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import PackageSection from "./Section/PackageSection/PackageSection";
import ApprovedSection from "./Section/ApprovedSection/ApprovedSection";
import Cream from "../../images/galaxy antibacterial.jpg";
import Soap from "../../images/galaxy-clean-img.jpg";

class Product extends Component {
	state = {
		pageTitle: "",
		product: null,
		videoMode: false,
		videoUrl: null,
		cosmetic: false,
	};

	flashy = window.flashy;

	componentDidMount() {
		const pageTitle = this.props.match.params.id;
		this.setState({ pageTitle: pageTitle }, () => {
			if (this.props.match.params.section !== "cosmetic-line") this.fetchDb();
			else this.setState({ cosmetic: true });
		});
	}

	fetchDb = () => {
		db.ref("/products/" + this.props.match.params.id.toLowerCase()).on(
			"value",
			(snapshot) => {
				let productObject = snapshot.val();
				// const product = Object.keys(productObject).map((key) => ({
				//   ...productObject[key]
				// }));
				this.setState(
					{
						product: productObject,
					},
					() => {
						if (this.props.match.params.id.toLowerCase() === "galaxy-100")
							this.galaxy100VideoUrlHandler();
						else this.setState({ videoUrl: this.state.product.video });
					}
				);
			}
		);
	};

	changeVideoMode = () => {
		this.setState({ videoMode: !this.state.videoMode });
	};

	galaxy100VideoUrlHandler = () => {
		switch (this.props.match.params.section) {
			case "aircraft-cleaners":
				this.setState({ videoUrl: this.state.product.video.aircraft });
				break;
			case "industry-cleaners":
				this.setState({ videoUrl: this.state.product.video.industry });
				break;
			case "rail-cleaners":
				this.setState({ videoUrl: this.state.product.video.rail });
				break;
			default:
		}
	};

	render() {
		let descriptionSection;
		let applicationsSection;
		let characteristicsSection;
		let benefitsSection;

		if (this.state.product) {
			descriptionSection = (
				<Section type="description" product={this.state.product} />
			);
			applicationsSection = (
				<Section type="applications" product={this.state.product} />
			);
			characteristicsSection = (
				<Section type="characteristics" product={this.state.product} />
			);
			benefitsSection = (
				<Section type="benefits" product={this.state.product} />
			);
		} else {
			descriptionSection = null;
			applicationsSection = null;
			characteristicsSection = null;
			benefitsSection = null;
		}

		let cosmeticImage;
		if (this.state.pageTitle.toLowerCase() === "galaxy-clean-19") {
			cosmeticImage = (
				<img className={styles.cosmeticImg} src={Soap} alt="soap" />
			);
		} else {
			cosmeticImage = (
				<img className={styles.cosmeticImg} src={Cream} alt="cream" />
			);
		}
		return (
			<>
				{this.state.cosmetic ? (
					<>
						<ProductHeader
							pageTitle={this.state.pageTitle}
							showButton={false}
						/>

						{cosmeticImage}
					</>
				) : (
					<>
						{this.state.product ? (
							<>
								<Modal
									show={this.state.videoMode}
									onHide={this.changeVideoMode}
									dialogClassName={styles.videoModal}
								>
									<Modal.Header closeButton />
									<Modal.Body>
										<ReactPlayer url={this.state.videoUrl} />
									</Modal.Body>
								</Modal>
								<ProductHeader
									pageTitle={this.state.pageTitle}
									showButton={this.state.videoUrl}
									changeVideoMode={this.changeVideoMode}
								/>
							</>
						) : null}

						<div className={`${styles.container}`}>
							<section className={styles.containerSection}>
								{descriptionSection}
							</section>
							<section
								className={`${styles.containerSection} ${styles.reverse}`}
							>
								{applicationsSection}
							</section>
							<section className={styles.containerSection}>
								{characteristicsSection}
							</section>
							<section
								className={`${styles.containerSection} ${styles.reverse}`}
							>
								{benefitsSection}
							</section>
							<section
								className={`${styles.containerSection} ${styles.packingHeadline}`}
							>
								{this.state.pageTitle ? (
									<PackageSection pageTitle={this.state.pageTitle} />
								) : null}
							</section>
							{this.props.match.params.id.toLowerCase() === "galaxy-100" ? (
								<section
									className={`${styles.containerSection} ${styles.packingHeadline} ${styles.reverse}`}
								>
									<ApprovedSection />
								</section>
							) : null}
						</div>
					</>
				)}
			</>
		);
	}
}

export default Product;
