import firebase from "firebase/app";
import "firebase/database";

const config = {
  apiKey: "AIzaSyB7bl6MoX1iDqYJfRNWA8VWFwTN7_Za3ck",
  authDomain: "green-galaxy-d5657.firebaseapp.com",
  databaseURL: "https://green-galaxy-d5657.firebaseio.com"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
export const db = firebase.database();
