import React, { Component } from "react";
import { withRouter } from "react-router";
import styles from "./featuredProductCard.module.css";
import cream from "../../images/cream.png";
import Liter18Pack from "../../images/18Liter.png";
import galaxy700 from "../../images/galaxy700.jpg";
import "animate.css/animate.min.css";
import ScrollTrigger from "react-scroll-trigger";
import { Card, Button } from "react-bootstrap";
import containers from "../../images/containers.png";
import whipes from "../../images/image3.png";

class FeaturedProductCard extends Component {
	state = { section: "", img: null, visible: false };

	onEnterViewport = () => {
		this.setState({
			visible: true,
		});
	};

	buttonHander = () => {
		this.props.history.push({
			pathname: this.state.section,
			state: { pageTitle: this.props.title.toLowerCase() },
		});
		// this.props.history.push(this.state.section + "/" + this.props.title);
	};

	componentDidMount() {
		switch (this.props.title.toLowerCase()) {
			case "galaxy 700":
				this.setState({
					img: (
						<div className={styles.imageContainer}>
							<img
								className={`${styles.whipesFeatured} card-img-top`}
								src={whipes}
								alt="Weapon Cleaner"
							/>
						</div>
					),
				});
				this.setState({ section: "weapon-cleaners" });
				break;
			case "galaxy-100":
				this.setState({
					img: (
						<div className={styles.imageContainer}>
							<img
								className={`${styles.containersFeatured} card-img-top`}
								src={containers}
								alt="Hand cleaner & disinfectant"
							/>
						</div>
					),
				});
				this.setState({ section: "industry-cleaners" });
				break;
			case "galaxy 300":
				this.setState({
					img: (
						<div className={styles.imageContainer}>
							<img
								className={`${styles.containersFeatured} card-img-top`}
								src={containers}
								alt="Hand cleaner & disinfectant"
							/>
						</div>
					),
				});
				this.setState({ section: "special-cleaners" });
				break;
			case "galaxy-antibacterial-hand-cream":
				this.setState({
					img: (
						<div className={styles.imageContainer}>
							<img
								className={`${styles.cosmetic} card-img-top`}
								src={cream}
								alt="cream"
							/>
						</div>
					),
				});
				this.setState({ section: "cosmetic-line" });
				break;
			default:
				this.setState({
					img: (
						<div className={styles.imageContainer}>
							<img
								className={`${styles.gunImage} card-img-top`}
								src={Liter18Pack}
								alt="gun"
							/>
						</div>
					),
				});
				this.setState({ section: "special-cleaners" });
		}
	}

	render() {
		const title = this.props.title.toUpperCase().replace(/-/g, " ");
		return (
			// <div className={styles.container}>
			//   <div className={styles.imageContainer}>
			//     {/* <div className={styles.wrap}> */}
			//     {/* <ScrollAnimation > */}
			//     <ScrollTrigger onEnter={this.onEnterViewport}>
			//       {this.state.visible ? (
			//         <div className={styles.ribbon}>RECOMMENDED</div>
			//       ) : null}
			//     </ScrollTrigger>
			//     {/* </ScrollAnimation> */}
			//     {this.state.img}
			//     {/* </div> */}
			//   </div>
			//   <h5>{title}</h5>
			//   <p>{this.props.description}</p>
			//   {this.state.section ? (
			//     <button onClick={this.buttonHander} className="width button">
			//       LEARN MORE
			//     </button>
			//   ) : null}
			// </div>
			<Card
				border="success"
				className="h-100 position-relative"
				style={{ overflow: "hidden" }}
			>
				<ScrollTrigger onEnter={this.onEnterViewport}>
					{this.state.visible ? (
						<div className={styles.ribbon}>RECOMMENDED</div>
					) : null}
				</ScrollTrigger>
				{this.state.img}
				<Card.Title className="text-center">{title}</Card.Title>
				{this.state.section ? (
					<>
						<Card.Body>{this.props.description}</Card.Body>
						<Card.Footer>
							<Button
								variant="outline-success"
								size="lg"
								block
								onClick={this.buttonHander}
							>
								LEARN MORE
							</Button>
						</Card.Footer>
					</>
				) : null}
			</Card>
		);
	}
}

export default withRouter(FeaturedProductCard);
