import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import railCleaners from "../../images/rail-cleaners.webp";
import industryCleaners from "../../images/industry-our-products.jpg";
import cosmeticLine from "../../images/cosmetic-line.webp";
import aircrafCleaners from "../../images/aircraft-industry.jpg";
import specialCleaners from "../../images/industry-mix-2.jpg";
import weapons from "../../images/gun-industry.jpg";
import "./specializations.css";

const Specializations = () => {
  const history = useHistory();
  return (
    <>
      <Container className="my-3">
        <section className="my-5">
          <h1 className="text-center bg-dark text-white">
            Our Products
          </h1>
        </section>
        <Row className="row-cols-1 row-cols-md-2 g-4">
          <Col>
            <Card className="bg-dark text-white mx-2 specialization-card">
              <Card.Img
                src={aircrafCleaners}
                alt="Card image"
                className="card-bg"
              />
              <Card.ImgOverlay className="d-flex flex-column justify-content-center">
                <h2 className="text-center">Aircraft Cleaners</h2>
                <Card.Text className="truncate-overflow text-left">
                  For exterior wash-down, interior cleaning and maintenance of
                  terminals in the aviation industry, our breakthrough formula
                  cleans and degreases without corrosive chemicals. These
                  cleaners combine high soil capacity, superior grease-cutting
                  ability and clean rinsing formula to provide deep, effective
                  and high precision cleaning. The absence of oxidizing reagents
                  prevents rust and corrosion, making it ideal for maintaining
                  structural metals, hoses, seals and aircraft finishes.
                </Card.Text>
                <Button
                  className="specialization-products-btn"
                  onClick={() => history.push("/aircraft-cleaners")}
                >
                  Learn More
                </Button>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            {" "}
            <Card className="bg-dark text-white mx-2 specialization-card">
              <Card.Img
                src={industryCleaners}
                alt="Card image"
                className="card-bg"
              />
              <Card.ImgOverlay className="d-flex flex-column justify-content-center">
                <h2 className="text-center">Industry Cleaners</h2>
                <Card.Text className="truncate-overflow text-left">
                  Our industry range of products caters for cleaning and
                  maintenance manufacturing properties. These formulations are
                  100% guaranteed free of dangerous additives and toxic chemical
                  and are best-in-class for day-to-day industrial purposes. For
                  any part or surface, we’ve designed befitting cleaners,
                  degreasers, lubricants and specialty chemicals that are
                  equally safe as effective.
                </Card.Text>

                <Button
                  className="specialization-products-btn"
                  onClick={() => history.push("/industry-cleaners")}
                >
                  Learn More
                </Button>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white mx-2 specialization-card">
              <Card.Img
                src={railCleaners}
                alt="Card image"
                className="card-bg"
              />
              <Card.ImgOverlay className="d-flex flex-column justify-content-center">
                <h2 className="text-center">Rail Cleaners</h2>
                <Card.Text className="truncate-overflow">
                  The demand of high performance cleaning chemicals and fluids
                  in the rail industry is on the high and we knew it. Green
                  Galaxy expend extra-effort in the formulation of
                  disinfectants, cleaners, lubricants and solvents, all tailored
                  to the rail industry requirements. For surface, floor, seat,
                  roof, internal and external cleaning, you can trust the Galaxy
                  line to deliver.
                </Card.Text>

                <Button
                  className="specialization-products-btn"
                  onClick={() => history.push("/rail-cleaners")}
                >
                  Learn More
                </Button>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white mx-2 specialization-card">
              <Card.Img
                src={specialCleaners}
                alt="Card image"
                className="card-bg"
              />
              <Card.ImgOverlay className="d-flex flex-column justify-content-center">
                <h2 className="text-center">Special Cleaners</h2>
                <Card.Text className="truncate-overflow">
                  We are committed to making mother earth a safe habitation, for
                  this reason we remain pedal-on in the production of
                  environmentally safe chemicals and solutions that can be used
                  in different fields.
                </Card.Text>

                <Button
                  className="specialization-products-btn"
                  onClick={() => history.push("/special-cleaners")}
                >
                  Learn More
                </Button>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white mx-2 specialization-card">
              <Card.Img
                src={cosmeticLine}
                alt="Card image"
                className="card-bg"
              />
              <Card.ImgOverlay className="d-flex flex-column justify-content-center">
                <h2 className="text-center">Antibacterial Solutions</h2>
                <Card.Text className="truncate-overflow">
                  In our bid to make the world a safer place, and besides our
                  wide range of industrial chemicals, We extending our tentacles
                  by create our line of cosmetic product based on extensive
                  research. Green Galaxy employs a human-centri approach in the
                  formulation of antibacterial creams and disinfectants that are
                  safe for human health. And there's more still coming...
                </Card.Text>

                <Button
                  className="specialization-products-btn"
                  onClick={() => history.push("/cosmetic-line")}
                >
                  Learn More
                </Button>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col>
            <Card className="bg-dark text-white mx-2 specialization-card">
              <Card.Img src={weapons} alt="Card image" className="card-bg" />
              <Card.ImgOverlay className="d-flex flex-column justify-content-center">
                <h2 className="text-center">Weapon Cleaners</h2>
                <Card.Text className="truncate-overflow">
                  Green Galaxy offers an extensive line of powerful fast-acting
                  cleaning and lubricant solutions for military, federal, state
                  and agencies as well as manufacturers, retailers, ranges and
                  invidual shooters. Formulated especially for today's gun
                  cleaning systems to thoroughly clean, contidtion, and protect
                  your firearms. Safe for all metal surfaces, including carbon
                  steel, aluminum, titanium, and stainless steel.
                  Environmentally safe, non-toxic and biodegradable solutions.
                  Effectively and save valuable time and money.
                </Card.Text>
                <Button
                  className="specialization-products-btn"
                  onClick={() => history.push("/weapon-cleaners")}
                >
                  Learn More
                </Button>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Specializations;
