import React from "react";
import styles from "./packCard.module.css";
import bigContainer from "../../../../../images/1000L.png";
import medContainer from "../../../../../images/200L.png";
import smallContainer from "../../../../../images/18L.png";

const PackCard = (props) => {
  let img, headline;
  switch (props.type) {
    case "small":
      img = <img src={smallContainer} alt="sm" />;
      headline = "18";
      break;
    case "medium":
      img = <img src={medContainer} alt="md" />;
      headline = "200";
      break;
    case "big":
      img = <img src={bigContainer} alt="bg" />;
      headline = "1000";
      break;
    default:
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.top}>{img}</div>
          <div className={styles.bottom}>
            <div className={styles.left}>
              <div className={styles.details}>
                <h1>{headline}L</h1>
                <p>
                  {props.pageTitle.toUpperCase().replace("-", "")}-{headline}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackCard;
